import React from 'react';
import {Route} from 'react-router-dom';
import { Redirect } from 'react-router';
import authenticationService from './AuthenticationService';

export const SecuredRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => {
        const currentUser = authenticationService.isAuthenticated();
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            // return <Redirect to={{ pathname: '/login', state: { from: props.location } }} />
            return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        }

        // authorised so return component
        return <Component {...props} />
    }} />

    
)