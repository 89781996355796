import { url } from '../config/config'
import axios from 'axios';

class Auth {
    constructor() {
        this.token = JSON.parse(localStorage.getItem("currentUser"));
        this.mobile = localStorage.getItem("mobile");
    }

    isAuthenticated() {
        return this.token != null && new Date().getTime() < this.token.expire;
    }

    getAuthToken(){
        return this.token.token;
    }

    getLoginTokenTime(){
        return this.token.expire;
    }
    getExpiryTokenTime(){
        return this.token.refreshToken;
    }

    getMobileNo() {
        return this.mobile;
    }

    signOut() {
         // remove user from local storage to log user out
         localStorage.removeItem('currentUser');
         localStorage.removeItem("mobile");
         localStorage.removeItem("response");
         localStorage.removeItem("acc_name");
         localStorage.removeItem("timer");
         this.token = null;
    }

    
    async login(username, password, authCode) {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                'username': username,
                'password': password,
                'uid': authCode
            })
        };

        console.log("request: " +JSON.stringify(requestOptions))

        return fetch(`${url}shred/login`, requestOptions)
            // .then(handleResponse)
            .then(response => {
                // console.log("Login Res: " + JSON.stringify(response));
                // if(response.text()===''){
                //     const error ='Invalid Username or Password';   
                //     return Promise.reject(error); 
                // }
               return  response.text().then(text => {
                console.log("Res: " + JSON.stringify(text));
                    const data = text && JSON.parse(text);
                    if (data.token==null ||!response.ok) {
                        
                        const error = (data && data.message) || (response.statusText ==''?"Invalid User":"");
            
                        return Promise.reject(error);
                    }
            
                    return data;
                });
            })
            
            .then(user => {
                console.log("USER" +JSON.stringify(user));
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(user));
                this.token = user;
                this.mobile = username;
                localStorage.setItem("mobile", username);
                console.log("User: ", JSON.stringify(user));
                return user;

            });
    }
}

const authenticationService = new Auth();

export default authenticationService;