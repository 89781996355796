import React from "react";
import { withRouter } from "react-router-dom";
import authenticationService from "../auth/AuthenticationService";
class login extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      userName: "",
      password: "",
      authCode: "",
      erroruserName: false,
      errorpassword: false,
      errorauthCode: false,
    };

    // redirect to home if already logged in
    if (authenticationService.isAuthenticated()) {
      this.props.history.push("/shred/trialBal");
    }
  }

  render() {
    return (
      <div>
        <input
          type="text"
          onBlur={(e) => {
            this.updateUserName(e.target.value);
          }}
          className={
            "form-control" + (this.state.erroruserName ? " is-invalid" : "")
          }
          placeholder="Username "
        />
        {this.state.erroruserName ? (
          <div className="invalid-feedback">{this.state.erroruserName}</div>
        ) : null}
        <br />
        <input
          type="text"
          onBlur={(e) => {
            this.updatePassword(e.target.value);
          }}
          className={
            "form-control" + (this.state.errorpassword ? " is-invalid" : "")
          }
          placeholder="Password "
        />
        <br />
        <input
          type="text"
          onBlur={(e) => {
            this.updateAuthCode(e.target.value);
          }}
          className={
            "form-control" + (this.state.errorauthCode ? " is-invalid" : "")
          }
          placeholder="Auth Code "
        />
        <br />
        <button className="btn btn-success" onClick={this.login.bind(this)}>
          Login
        </button>
      </div>
    );
  }

  updateUserName(value) {
    this.setState({
      userName: value,
    });
  }

  updatePassword(value) {
    this.setState({
      password: value,
    });
  }

  updateAuthCode(value) {
    this.setState({
      authCode: value,
    });
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  login() {
    this.setState({
      erroruserName: false,
      errorpassword: false,
      errorauthCode: false,
    });
    authenticationService
      .login(this.state.userName, this.state.password, this.state.authCode)
      .then(
        (user) => {
          const { from } = this.props.location.state || {
            from: { pathname: "/shred/trialBal" },
          };
          this.props.history.push(from);
        },
        (error) => {
          let errorMessage;
          if (error === "error.http.401") {
            errorMessage = "Invalid! Username or Password or Auth Code";
          } else {
            errorMessage = error.toString();
          }
          this.setState({
            erroruserName: errorMessage,
            disabled: false,
          });
        }
      );

    // this.props.history.push('/'+this.props.location);
  }
}

export default withRouter(login);
