import React from "react";
import axios from "axios";
import { handleResponse } from "../auth/handle-response";
import { url } from "../config/config";
import authenticationService from "../auth/AuthenticationService";
import { Link } from "react-router-dom";
import "jquery/dist/jquery.min.js";
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import $ from "jquery";
import "react-notifications-component/dist/theme.css";
import { store } from "react-notifications-component";

class TrailBalance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      items: null,
      credit: 0,
      debit: 0,
      led: 0,
      filteredData: null,
      timer: 15 * 60,
    };
  }

  async componentDidMount() {
    const {
      match: { params },
    } = this.props;

    await axios
      .get(`${url}shred/trialBal`, {
        params: {
          key: authenticationService.getAuthToken(),
        },
      })
      .then(handleResponse)
      .then((res) => {
        {
          this.updateDebitCredit(res);
        }
        this.setState({
          data: res,
          items: res,
          group: params.ledId,
          filteredData: res,
        });
      });
    this.startTimer();
    this.setState({});
  }

  componentWillUnmount() {
    // Clear the timer when the component unmounts
    clearInterval(this.timerID);
  }

  startTimer = () => {
    this.timerID = setInterval(() => {
      this.setState(
        (prevState) => ({
          timer: prevState.timer - 1,
        }),
        () => {
          if (this.state.timer <= 0) {
            authenticationService.signOut();
            this.props.history.replace('/');
          }
        }
      );
    }, 1000); // Update timer every second
  };

  updateDebitCredit(filteredData) {
    var creditSum = 0;
    var debitSum = 0;
    var ledSum = 0;

    filteredData.forEach((element) => {
      if (element.balance < 0) {
        creditSum += element.balance;
      } else {
        debitSum += element.balance;
      }
    });
    ledSum = filteredData.length;
    this.setState({
      credit: creditSum,
      debit: debitSum,
      led: ledSum,
    });
  }

  handleFiltering(searchQuery) {
    const filteredData = this.state.data.filter((item) => {
      const searchRegex = new RegExp(searchQuery, "i");
      return (
        searchRegex.test(item.groupName) ||
        searchRegex.test(item.ledName) ||
        searchRegex.test(item.ledDesc) ||
        searchRegex.test(item.city) ||
        searchRegex.test(item.mobile) ||
        searchRegex.test(item.comment) ||
        searchRegex.test(item.balance)
      );
    });
    this.setState({ filteredData }); // Update filteredData state
    this.updateDebitCredit(filteredData); // Update debit and credit based on filtered data
  }

  formatAmount(val) {
    if (val !== undefined) {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(val);
    }
  }

  formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  async accStatement(val, accName) {
    await axios
      .post(`${url}shred/stmt`, null, {
        params: {
          key: authenticationService.getAuthToken(),
          id: val,
        },
      })
      .then(handleResponse)
      .then((res) => {
        console.log("Res: " + JSON.stringify(res));
        if (res != null && res.length > 0) {
          localStorage.setItem("response", JSON.stringify(res));
          localStorage.setItem("acc_name", accName);
          window.open("../shred/stmt/", "_blank");
        } else {
          store.addNotification({
            title: "Error!",
            message: "No Statement Found",
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 400,
              onScreen: true,
            },
          });
          return;
        }
      });
  }

  render() {
    return (
      <div>
        <div class="container-fluid mt-3">
          <h1 class="text-center font-weight-bold">Trial Balance</h1>
          <p>Timer: {this.formatTime(this.state.timer)}</p>
        </div>

        <div class="container-fluid">
          <div class="">
            <div>
              {this.state.data === null && <p>Loading data...</p>}

              {this.state.data && (
                <div class="table-responsive">
                  <div class="row">
                    <div class="col">
                      {/* <p>Ledgers: {this.state.data.length} </p> */}
                    </div>
                    <div class="col">
                      {/* <p>Ledgers: {this.state.data.length} </p> */}
                    </div>
                    <div class="col">
                      <p>L: {this.state.led} </p>
                    </div>
                    <div class="col">
                      <input
                        type="text"
                        placeholder="Type anything..."
                        value={this.state.searchQuery}
                        onChange={(e) => this.handleFiltering(e.target.value)}
                      />
                    </div>
                    <div class="col">
                      {/* <p>Ledgers: {this.state.data.length} </p> */}
                    </div>
                    {/* <div class="col">
                      <p>Ledgers: {this.state.data.length} </p>
                    </div> */}
                    <div class="col">
                      <p>D: {this.formatAmount(this.state.debit)}</p>
                    </div>
                    <div class="col">
                      <p>C: {this.formatAmount(Math.abs(this.state.credit))}</p>
                    </div>
                    <div class="col">
                      <p>
                        {/* Credit: {this.formatAmount(Math.abs(this.state.credit))} */}
                      </p>
                    </div>
                  </div>
                  <table class="table table-bordered border-primary table-sm align-middle align">
                    <thead>
                      <tr>
                        <th>Group</th>
                        <th>Ledger</th>
                        <th>Description</th>
                        <th>Station</th>
                        <th>Mobile</th>
                        <th>Comment</th>
                        <th>Amount(Debit)</th>
                        <th>Amount(Credit)</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredData &&
                        this.state.filteredData.map((item) => (
                          <tr key={item.ledId}>
                            <td class="align-middle h5"> {item.groupName} </td>
                            <td class="align-middle h5"> {item.ledName} </td>
                            <td class="align-middle h5"> {item.ledDesc}</td>
                            <td class="align-middle h5"> {item.city}</td>
                            <td class="align-middle h5"> {item.mobile}</td>
                            <td class="align-middle h5"> {item.comment}</td>
                            {/* Debit */}
                            <td class="align-middle h5 text-right">
                              {item.balance > 0
                                ? this.formatAmount(item.balance)
                                : ""}
                            </td>
                            <td class="align-middle h5 text-right">
                              {/* Credit */}
                              {item.balance < 0
                                ? this.formatAmount(Math.abs(item.balance))
                                : ""}
                            </td>
                            <td class="align-middle">
                              <button
                                class="btn btn-secondary"
                                id="btnDetail"
                                onClick={(e) =>
                                  this.accStatement(item.ledId, item.ledName)
                                }
                              >
                                Details
                              </button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TrailBalance;
