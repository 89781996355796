import React from "react";
import axios from "axios";
import { handleResponse } from "../auth/handle-response";
import { url } from "../config/config";
import { Link } from "react-router-dom";
import authenticationService from "../auth/AuthenticationService";
import "react-notifications-component/dist/theme.css";
import { store } from "react-notifications-component";

class AccountStatement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      items: null,
      response: null,
      filteredData: null,
      acc_name: null
    };
  }

  componentDidMount() {
    var res = JSON.parse(localStorage.getItem("response"));
    this.state.data = res;
    this.state.items = res;
    this.state.filteredData = res;
    this.state.acc_name = localStorage.getItem("acc_name");
    this.updateDebitCredit(this.state.filteredData);
  }


  updateDebitCredit(filteredData) {
    var creditSum = 0;
    var debitSum = 0;
    var ledSum = 0;

    filteredData.forEach((element) => {
        creditSum += parseFloat(element.credit);
        debitSum += parseFloat(element.debit);
    });
    ledSum = filteredData.length;
    this.setState({
      credit: creditSum,
      debit: debitSum,
      led: ledSum,
    });
  }

  handleFiltering(searchQuery) {
    const filteredData = this.state.data.filter((item) => {
      const searchRegex = new RegExp(searchQuery, "i");
      return (
        searchRegex.test(item.v_date) ||
        searchRegex.test(item.narration) ||
        searchRegex.test(item.v_no) ||
        searchRegex.test(item.cheque_no) ||
        searchRegex.test(item.credit) ||
        searchRegex.test(item.debit) ||
        searchRegex.test(item.balance)
      );
    });
    this.setState({ filteredData }); // Update filteredData state
    this.updateDebitCredit(filteredData); // Update debit and credit based on filtered data
  }

  formatAmount(val) {
    if (val !== undefined) {
      return new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(val);
    }
  }

  render() {
    return (
      <div>
        <div class="container-fluid mt-3">
          <h1 class="text-center font-weight-bold">Account Statement of {this.state.acc_name}</h1>
        </div>

        <div class="container-fluid">
          <div class="">
            <div>
              {this.state.data === null && <p>Loading data...</p>}

              {this.state.data && (
                <div class="table-responsive">
                  <div class="row">
                    <div class="col">
                      {/* <p>Ledgers: {this.state.data.length} </p> */}
                    </div>
                    <div class="col">
                      {/* <p>Ledgers: {this.state.data.length} </p> */}
                    </div>
                    <div class="col">
                      <p>L: {this.state.led} </p>
                    </div>
                    <div class="col">
                      <input
                        type="text"
                        placeholder="Type anything..."
                        value={this.state.searchQuery}
                        onChange={(e) => this.handleFiltering(e.target.value)}
                      />
                    </div>
                    <div class="col">
                      {/* <p>Ledgers: {this.state.data.length} </p> */}
                    </div>
                    {/* <div class="col">
                      <p>Ledgers: {this.state.data.length} </p>
                    </div> */}
                    <div class="col">
                      <p>D: {this.formatAmount(this.state.debit)}</p>
                    </div>
                    <div class="col">
                      <p>C: {this.formatAmount(Math.abs(this.state.credit))}</p>
                    </div>
                    <div class="col">
                      <p>
                        {/* Credit: {this.formatAmount(Math.abs(this.state.credit))} */}
                      </p>
                    </div>
                  </div>
                  <table class="table table-bordered border-primary table-sm align-middle align">
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Perticulars</th>
                        <th>Cheque No.</th>
                        <th>Folio/No.</th>
                        <th>Debit</th>
                        <th>Credit</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredData &&
                        this.state.filteredData.map((item) => (
                          <tr key={item.ledId}>
                            <td class="align-middle h5"> {item.v_date} </td>
                            <td class="align-middle h5"> {item.narration} </td>
                            <td class="align-middle h5"> {item.cheque_no}</td>
                            <td class="align-middle h5"> {item.v_no}</td>
                            <td class="align-middle h5 text-right">
                              {" "}
                              {item.debit > 0 ? item.debit : ""}
                            </td>
                            <td class="align-middle h5 text-right">
                              {" "}
                              {item.credit > 0 ? item.credit : ""}
                            </td>
                            <td class="align-middle h5 text-right">
                              {" "}
                              {item.balance}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountStatement;
