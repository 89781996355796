import React from 'react';
import './App.css';
import HeaderNavBar from './navbar/NavBar';
import { useLocation } from 'react-router-dom'
import LoginView from './components/LoginView';
import { Route } from 'react-router';
import FooterBar from './navbar/FooterBar';
import { BrowserRouter as Redirect } from 'react-router-dom';
import TrailBalance from './components/TrailBalance';
import { SecuredRoute } from './auth/SecuredRoute';
import AccountStatement from './components/AccountStatement';

function App() {
 return (
    // <div className={ useLocation().pathname === '/login'? "loginbg_img"  : null } >
          <div className={ useLocation().pathname === '/'? "loginbg_img"  : null } >
    <div className="main">
       <HeaderNavBar />
       {/* <div className="container " style={{marginTop: 50}}></div> */}
      <div>
        {/* <Route exact path="/login" component={LoginView} /*/}
        {/* <Route exact path="/">
          <Redirect to="/login" /> 
        </Route>  */}
        <SecuredRoute exact path='/shred/trialBal' component={TrailBalance} />
        <SecuredRoute exact path='/shred/stmt' component={AccountStatement} />

        {/* <Route exact path="/login" component={LoginView} /> */}
        <Route exact path="/" component={LoginView} />
      
      </div>
    </div>
    <FooterBar />
   </div>
  );
  
}


export default App;
